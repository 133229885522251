.about-section {
  text-align: center;
  background-color: #eefafc;
  flex-direction: column;
  gap: 40px;
  padding: 75px 10em;
  display: flex;
}

.about-section h3 {
  font-size: 2.5em;
  font-weight: 600;
}

.about-section p {
  font-size: 20px;
  line-height: 2;
}

@media screen and (width <= 800px) {
  .about-section {
    padding: 75px 1em;
  }

  .about-section h3 {
    font-size: 1.5em;
    font-weight: 600;
  }

  .about-section p {
    font-size: 15px;
    line-height: 1.5;
  }
}

.objective-section {
  background-color: #f8fafd;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 75px 0;
  display: flex;
}

.objective-section h3 {
  font-size: 2.5em;
  font-weight: 600;
}

.objective-wrapper {
  flex-flow: wrap;
  justify-content: space-evenly;
  gap: 0 2em;
  display: flex;
}

.cards {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em 0;
  width: 320px;
  display: flex;
}

.objective-img-cont {
  width: 95px;
}

.card-details {
  text-align: center;
}

.card-details h4 {
  font-size: 1.5em;
}

.card-details p {
  color: #292f3680;
  font-size: .9em;
}
/*# sourceMappingURL=index.c6389d2f.css.map */
