.about-section{
background-color: #eefafc;
padding: 75px 10em;
text-align: center;
display: flex;
flex-direction: column;
gap: 40px;

h3{
    font-size: 2.5em;
    font-weight: 600;
}
p{
    font-size: 20px;
    line-height: 2;
}

@media screen and (max-width: 800px) {
  padding: 75px 1em;

  h3{
    font-size: 1.5em;
    font-weight: 600;
}

p{
    font-size: 15px;
    line-height: 1.5;
}

}   

}

.objective-section{
    padding: 75px 0;
    background-color: #f8fafd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    h3{
        font-size: 2.5em;
        font-weight: 600;
    }

}


.objective-wrapper {
    gap: 0 2em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.cards {
    width: 320px;
    gap: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.objective-img-cont {
    width: 95px;
}

.card-details {
    text-align: center;
    h4 {
      font-size: 1.5em;
    }
  
    p {
      color: rgba(41, 47, 54, 0.5);
      font-size: 0.9em;

    }
}